import _axios from '../plugins/axios';
export const Branches = {
	state: {
		branches: []
	},

	getters: {
		getBranches(state) {
			return state.branches;
		}
	},

	mutations: {
		setBranches(state, data) {
			state.branches = data;
		},
		setBranch(state, data) {
			const index = state.branches.findIndex(d => d.id === data.id);
			if (index === -1)
				state.branches.push(data);
			else
				state.branches[index] = data;
		},
        deleteBranch(state,data){
            const i = state.branches.map(item => item.id).indexOf(data.id);
            state.branches.splice(i, 1);
        }
	},

	actions: {
		setBranch({ commit }, data) {
			commit('setBranch', data);
		},
        deleteBranch({ commit }, data) {
            commit('deleteBranch', data);
        },
		fetchBranches({commit}) {
			_axios.get('/api/v1/branches').then(response => {
				commit('setBranches', response.data);
			});
		}
	}
};


