import _axios from '../plugins/axios';
export const BeedeeAgents = {
	state: {
		relationship_managers: []
	},

	getters: {
		getBeedeeAgents(state) {
			return state.relationship_managers;
		}
	},

	mutations: {
		setBeedeeAgents(state, data) {
			state.relationship_managers = data;
		}
	},

	actions: {
		fetchBeedeeAgents({commit}) {
			_axios.get('/api/v1/users/beedee_agents').then(response => {
				let users = response.data.map(x => {
					return {
						"id": x.id,
						"fullname": [x.firstname, x.middlename, x.lastname].join(' '),
					}
				});
				commit('setBeedeeAgents', users);
			});
		}
	}
};