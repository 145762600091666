import Vue from 'vue'
import VueX from 'vuex'
import App from './App.vue'
import store from './store'
import router from './router'
import _axios from './plugins/axios'

import VueInsProgressBar from 'vue-ins-progress-bar'
import VueToastr from "vue-toastr"
import VueSweetalert2 from 'vue-sweetalert2'
import VueSelect from 'vue-select'
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
import DateRangePicker from 'vue2-daterange-picker'
import JsonExcel from 'vue-json-excel'
import VueNVD3 from 'vue-nvd3'
import VueApexCharts from 'vue-apexcharts'
import VueGoodTable from 'vue-good-table'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VeeValidate from "vee-validate"
import VueNumeric from 'vue-numeric'
import Avatar from 'vue-avatar'

import Pagination from "./components/Pagination"
import PhoneNumber from "./components/PhoneNumber"
import Loading from "./components/Loading"
import Modal from "./components/Modal"
import VuePanel from './plugins/panel/'

import {Auth} from './plugins/auth'
import {Errors} from './plugins/errors'
import {Permissions} from './plugins/permissions'
import {Numeral} from './plugins/numeral'
import FormValidator from './plugins/form'


//plugins css
import 'vue-select/dist/vue-select.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import '@/scss/vue.scss'
import "./filters";


Vue.config.productionTip = false

Vue.use(VueNVD3)
Vue.use(VuePanel)
Vue.use(VueX)
Vue.use(VueSweetalert2)
Vue.use(VueGoodTable)
Vue.use(VueToastr)
Vue.use(VeeValidate)
Vue.use(VueNumeric)
Vue.use(VueInsProgressBar, {
    position: 'fixed',
    show: true,
    height: '3px'
})

Vue.use(Numeral)
Vue.component('v-select', VueSelect)
Vue.component('datepicker', VueDatepicker)
Vue.component('downloadExcel', JsonExcel)
Vue.component('apexchart', VueApexCharts)
Vue.component('avatar', Avatar)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)

Vue.component('loading', Loading)
Vue.component('phone-number', PhoneNumber)
Vue.component('modal', Modal)
Vue.component('pagination', Pagination)

//my plugins
Vue.use(Permissions)
Vue.use(Errors)
Vue.use(Auth)
Vue.use(FormValidator)

_axios.interceptors.request.use(function (config) {
    app.$insProgress.start();
    return config;
});

_axios.interceptors.response.use(
    function (response) {
        app.$insProgress.finish();
        return response;
    },
    function (error) {
        app.$insProgress.finish();
        if (error.response.status === 401 && router.currentRoute.name !== 'Login') {
            app.$auth.logout();
            router.replace({name: 'Login'});
        }
        return Promise.reject(error);
    }
);

const app = new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')


