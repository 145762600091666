import _axios from "@/plugins/axios";
export const TabFour = {
    state: {
        exposure: [],

    },
    getters: {
        getTabFourExposure(state) {
            return state.exposure;
        },
    },
    mutations: {
        setTabFourExposure(state, data) {
            state.exposure = data;
        }
    },
    actions: {
        fetchTabFourExposure({commit}) {
            _axios.get('/api/v1/reports/dashboard/tab-four').then(response => {
                commit('setTabFourExposure', response.data);
            });
        }
    },
}
