import moment from 'moment/src/moment';
import _axios from '../plugins/axios';

export const Report = {
    state: {
        bidbonds: [],
        bidbonds_summary: [],
        // bid_expiry: [],
        opportunity: [],
        company_summary: [],
        company_rms: [],
        company_creator: [],
        // company_commission: [],
        rm_summary: [],
        rm_bids: [],
        beedee_agent_bids:[],
        // bids_by_creator: [],
        // payments: [],
        tenders: [],
        tenderleads: [],
    },

    getters: {
        getBidbonds(state) {
            return state.bidbonds;
        },
        // getPayments(state) {
        //     return state.payments;
        // },
        getBidbondsSummary(state) {
            return state.bidbonds_summary;
        },
        getCommissionSummary(state) {
            return state.bidbonds_summary.length  === 0 ? 0 : state.bidbonds_summary.map(i => i.bidbond_count).reduce((a, b) => a + b);
        },
        getRMSummary(state) {
            return state.rm_summary;
        },
        getRMBids(state) {
            return state.rm_bids;
        },
        getBeedeeAgentBids(state) {
            return state.beedee_agent_bids;
        },
        // getBidsByCreator(state) {
        //     return state.bids_by_creator;
        // },
        // getBidExpiry(state) {
        //     return state.bid_expiry;
        // },
        getOpportunity(state) {
            return state.opportunity;
        },
        getCompanySummary(state) {
            return state.company_summary;
        },
        getCompanyRMs(state) {
            return state.company_rms;
        },
        getCompanyCreator(state) {
            return state.company_creator;
        },
        // getCompanyCommission(state) {
        //     return state.company_commission;
        // },
        getTenders(state) {
            return state.tenders;
        },
        getTenderLeads(state) {
            return state.tenderleads;
        },
    },

    mutations: {
        bidbonds(state, data) {
            state.bidbonds = data;
        },
        // payments(state, data) {
        //     state.payments = data;
        // },
        bidbondsSummary(state, data) {
            state.bidbonds_summary = data;
        },
        RMSummary(state, data) {
            state.rm_summary = data;
        },
        RMBids(state, data) {
            state.rm_bids = data;
        },
        BeedeeAgentBids(state, data) {
            state.beedee_agent_bids = data;
        },
        // BidsByCreator(state, data) {
        //     state.bids_by_creator = data;
        // },
        // bidExpiry(state, data) {
        //     state.bid_expiry = data;
        // },
        opportunity(state, data) {
            state.opportunity = data;
        },
        companySummary(state, data) {
            state.company_summary = data;
        },
        companyRMs(state, data) {
            state.company_rms = data;
        },
        companyCreator(state, data) {
            state.company_creator = data;
        },
        // companyCommission(state, data) {
        //     state.company_commission = data;
        // },
        tenders(state, data) {
            state.tenders = data;
        },
        tenderleads(state, data) {
            state.tenderleads = data;
        },
    },
    actions: {
        fetchBidBonds({commit}, payload) {
            _axios.get('/api/v1/reports/bidbonds', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                }
            }).then(response => {
                commit('bidbonds', response.data);
            });
        },
        // fetchPayments({commit}, payload) {
        //     _axios.get('/api/v1/reports/bidbonds/payments', {
        //         params: {
        //             start: moment(payload.start).format("YYYY-MM-DD"),
        //             end: moment(payload.end).format("YYYY-MM-DD"),
        //         }
        //     }).then(response => {
        //         commit('payments', response.data);
        //     });
        // },
        fetchRMSummary({commit}, payload) {
            _axios.get('/api/v1/reports/bidbonds/rms', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                    branch_id: payload.branch_id
                }
            }).then(response => {
                commit('RMSummary', response.data);
            });
        },
        fetchRMBids({commit}, payload) {
            _axios.get('/api/v1/reports/bidbonds/rm-bids', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                    branch_id: payload.branch_id
                }
            }).then(response => {
                commit('RMBids', response.data);
            });
        },
        fetchBeedeeAgentBids({commit}, payload) {
            _axios.get('/api/v1/reports/bidbonds/beedee-agents', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                    branch_id: payload.branch_id
                }
            }).then(response => {
                commit('BeedeeAgentBids', response.data);
            });
        },
        // fetchBidsByCreator({commit}, payload) {
        //     _axios.get('/api/v1/reports/bidbonds/bids-by-creator', {
        //         params: {
        //             start: moment(payload.start).format("YYYY-MM-DD"),
        //             end: moment(payload.end).format("YYYY-MM-DD"),
        //         }
        //     }).then(response => {
        //         commit('BidsByCreator', response.data.bids);
        //     });
        // },
        fetchBidBondSummary({commit}, payload) {
            _axios.get('/api/v1/reports/bidbonds/summary', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                }
            }).then(response => {
                commit('bidbondsSummary', response.data);
            });
        },
        // fetchBidBondExpiry({commit}, payload) {
        //     _axios.get('/api/v1/reports/bidbonds/expiry', {
        //         params: {
        //             start: moment(payload.start).format("YYYY-MM-DD"),
        //             end: moment(payload.end).format("YYYY-MM-DD"),
        //         }
        //     }).then(response => {
        //         commit('bidExpiry', response.data);
        //     });
        // },
        fetchOpportunity({commit}, payload) {
            _axios.get('/api/v1/reports/tenders/opportunity', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                }
            }).then(response => {
                commit('opportunity', response.data);
            });
        },
        fetchCompanySummary({commit}, payload) {
            _axios.get('/api/v1/reports/bidbonds/company-summary', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                }
            }).then(response => {
                commit('companySummary', response.data);
            });
        },
        fetchCompanyRMs({commit}, payload) {
            _axios.get('/api/v1/reports/companies/rms', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                }
            }).then(response => {
                commit('companyRMs', response.data);
            });
        },
        fetchCompanyCreator({commit}, payload) {
            _axios.get('/api/v1/reports/companies/creator', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                }
            }).then(response => {
                commit('companyCreator', response.data);
            });
        },
        // fetchCompanyCommission({commit}, payload) {
        //     _axios.get('/api/v1/reports/companies/commission', {
        //         params: {
        //             start: moment(payload.start).format("YYYY-MM-DD"),
        //             end: moment(payload.end).format("YYYY-MM-DD"),
        //         }
        //     }).then(response => {
        //         commit('companyCommission', response.data);
        //     });
        // },
        fetchTenders({commit}, payload) {
            _axios.get('/api/v1/tenders', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                }
            }).then(response => {
                commit('tenders', response.data);
            });
        },
        fetchTenderLeads({commit}, payload) {
            _axios.get('/api/v1/tenders/leads', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                }
            }).then(response => {
                commit('tenderleads', response.data);
            });
        },
    }
};