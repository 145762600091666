import _axios from "@/plugins/axios";
export const TabSeven = {
    state: {
        revenue: []
    },
    getters: {
        getBidPaymentsTabSeven(state) {
            return state.revenue;
        },
    },
    mutations: {
        setBidPaymentsTabSeven(state, data) {
            state.revenue = data;
        }
    },
    actions: {
        fetchBidPaymentsTabSeven({commit}, payload) {
            _axios.get('/api/v1/reports/dashboard/tab-seven', {
                params: {
                    start_date: payload.start,
                    end_date: payload.end
                }
            }).then(response => {
                commit('setBidPaymentsTabSeven', response.data);
            });
        }
    },
}
