<template>
  <ul class="nav">
    <li class="nav-profile">
      <a href="#" v-on:click="expand()">
        <div class="image">
          <span class="fa fa-2x fa-user-circle" />
        </div>
        <div class="info text-white">
          <b class="caret pull-right"></b>
          {{ fullname(user) }}
          <small>{{ user.role |unslug }}</small>
        </div>
      </a>
    </li>
    <li>
      <ul class="nav nav-profile"
          v-bind:class="{ 'd-block': this.stat === 'expand' && !this.pageOptions.pageSidebarMinified, 'd-none': this.stat === 'collapse' }">
        <li>
          <a href="/forgot-password"><i class="fa fa-key"></i> Reset password? </a>
          <a @click="logout"><i class="fa fa-lock"></i> Log Out</a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import PageOptions from '../../config/PageOptions.vue'
import {mapGetters} from "vuex";
import helper from "@/mixins/helper";

export default {
  name: 'SidebarNavProfile',
  mixins: [helper],
  data() {
    return {
      stat: '',
      pageOptions: PageOptions
    }
  },
  computed: {
    ...mapGetters({
      user: 'getAuthUser'
    }),
    firstname() {
      let name = this.user.firstname;
      return name != null ? name : 'user';
    },
    is_logged_in() {
      return !!this.user.email;
    }
  },
  methods: {
    expand: function () {
      this.stat = (this.stat === 'expand') ? 'collapse' : 'expand'
    },
    logout() {
      this.$axios.post('/api/v1/logout').then().finally(() => {
        this.$auth.logout();
        window.location.href = this.$router.resolve({name: 'Home'}).href;
      });
    }
  }
}
</script>
