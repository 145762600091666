<template>
  <div>
    <!-- begin #header -->
    <div id="header" class="header navbar-default">
      <!-- begin navbar-header -->
      <div class="navbar-header">
        <button v-if="pageOptions.pageWithTwoSidebar" class="navbar-toggle pull-left" type="button"
                v-on:click="toggleMobileRightSidebar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <router-link v-if="is_logged_in" :to="{ name: 'dashboard'}" class="navbar-brand">
          <img src="/logo.jpg" :alt="app">
        </router-link>
        <router-link v-else :to="{ name: 'Home'}" class="navbar-brand"><span class="navbar-logo"></span>
          {{ app }}
        </router-link>
        <button v-if="pageOptions.pageWithTopMenu && !pageOptions.pageWithoutSidebar"
                class="navbar-toggle pt-0 pb-0 mr-0 collapsed" type="button"
                v-on:click="toggleMobileTopMenu">
					<span class="fa-stack fa-lg text-inverse">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
        </button>
        <button v-if="pageOptions.pageWithTopMenu && pageOptions.pageWithoutSidebar" class="navbar-toggle" type="button"
                v-on:click="toggleMobileTopMenu">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button v-if="pageOptions.pageWithMegaMenu" class="navbar-toggle p-0 m-r-0" type="button"
                v-on:click="toggleMobileMegaMenu">
					<span class="fa-stack fa-lg text-inverse m-t-2">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
        </button>
        <button v-if="!pageOptions.pageWithoutSidebar" class="navbar-toggle" type="button"
                v-on:click="toggleMobileSidebar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!-- end navbar-header -->

      <!--			<header-mega-menu v-if="pageOptions.pageWithMegaMenu"></header-mega-menu>-->

      <!-- begin header-nav -->
      <ul class="navbar-nav navbar-right">
        <li v-if="is_logged_in" :class="{'show' :showLogMenu}" class="dropdown navbar-user">
          <a class="dropdown-toggle" data-toggle="dropdown" href="#" @click.prevent="toggleShowLogMenu">
            <avatar :size=30 :username="username" class="float-left" style="margin-top: -5px"></avatar>
            <span class="ml-1 d-none d-md-inline">{{ username }}</span> <b class="fa fa-caret-down"></b>
          </a>
          <div class="dropdown-menu dropdown-menu-right" :class="{'show' :showLogMenu}"
               style="position: absolute; transform: translate3d(-16px, 50px, 0); top: 0; left: 0; will-change: transform;">
            <a class="dropdown-item" href="/forgot-password">Reset password? </a>
            <a class="dropdown-item" @click="logout">Log Out</a>
          </div>
        </li>
        <li v-else>
          <router-link :to="{name: 'Login'}" class="dropdown-item">Log In</router-link>
        </li>
        <li v-if="pageOptions.pageWithTwoSidebar" class="divider d-none d-md-block"></li>
        <li v-if="pageOptions.pageWithTwoSidebar" class="d-none d-md-block">
          <a class="f-s-14" href="javascript:;" v-on:click="toggleRightSidebarCollapsed">
            <i class="fa fa-th"></i>
          </a>
        </li>
      </ul>
      <!-- end header navigation right -->
    </div>
    <!-- end #header -->
  </div>
</template>

<script>
import PageOptions from '../../config/PageOptions.vue'
import {mapGetters} from "vuex";

export default {
  name: 'Header',
  data() {
    return {
      pageOptions: PageOptions,
      app: process.env.VUE_APP_NAME,
      showLogMenu: 0
    }
  },
  computed: {
    ...mapGetters({
      user: 'getAuthUser'
    }),
    username() {
      if (this.user) {
        const n = ['firstname', 'lastname'];
        let names = Object.keys(this.user)
            .map((key) => {
              if (n.indexOf(key) !== -1)
                return this.user[key];
            });
        return names.join(' ');
      } else {
        return "N/A";
      }
    },
    is_logged_in() {
      return !!this.user.email;
    }
  },
  methods: {
    toggleShowLogMenu() {
      this.showLogMenu = !this.showLogMenu;
    },
    toggleMobileSidebar() {
      this.pageOptions.pageMobileSidebarToggled = !this.pageOptions.pageMobileSidebarToggled;
    },
    toggleMobileRightSidebar() {
      this.pageOptions.pageMobileRightSidebarToggled = !this.pageOptions.pageMobileRightSidebarToggled;
    },
    toggleMobileTopMenu() {
      this.pageOptions.pageMobileTopMenu = !this.pageOptions.pageMobileTopMenu;
    },
    toggleMobileMegaMenu() {
      this.pageOptions.pageMobileMegaMenu = !this.pageOptions.pageMobileMegaMenu;
    },
    toggleRightSidebar() {
      this.pageOptions.pageRightSidebarToggled = !this.pageOptions.pageRightSidebarToggled;
    },
    toggleRightSidebarCollapsed() {
      this.pageOptions.pageRightSidebarCollapsed = !this.pageOptions.pageRightSidebarCollapsed;
    },
    logout() {
      this.$axios.post('/api/v1/logout').then().finally(() => {
        this.$auth.logout();
        window.location.href = this.$router.resolve({name: 'Home'}).href;
      });
    }
  }
}
</script>
