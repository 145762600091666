<template>
  <div>
    <!-- begin #header -->
    <div id="header" class="header navbar navbar-default navbar-fixed-top navbar-expand-lg">
      <!-- begin container -->
      <div class="container">
        <!-- begin navbar-brand -->
        <router-link :to="{ name: 'Home'}" class="navbar-brand">
          <img src="/logo.jpg" :alt="app">
        </router-link>
        <!-- end navbar-brand -->
        <!-- begin navbar-toggle -->
        <button class="navbar-toggle collapsed" data-target="#header-navbar" data-toggle="collapse"
                type="button" @click="showTopMenu = !showTopMenu">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <!-- end navbar-header -->
        <!-- begin navbar-collapse -->
        <div id="header-navbar" :class="{'show': showTopMenu}" class="collapse navbar-collapse">
          <ul class="nav navbar-nav navbar-right">
            <li class="nav-item">
              <router-link :to="{ name: 'Home'}" class="nav-link">HOME</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-click="scroll-to-target" @click.prevent="scrollTo('about')">ABOUT</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-click="scroll-to-target" @click.prevent="scrollTo('quote')">GET QUOTE</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-click="scroll-to-target" @click.prevent="scrollTo('validate-bidbond')">VALIDATE
                BIDBOND</a>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'Login'}" class="nav-link"><span class="fa fa-unlock"></span> LOG IN
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'register'}" class="nav-link"><span class="fa fa-sign-in-alt"></span> SIGN UP
              </router-link>
            </li>
          </ul>
        </div>
        <!-- end navbar-collapse -->
      </div>
      <!-- end container -->
    </div>
    <!-- end #header -->
  </div>
</template>
<style lang="scss">
.content-title {
  text-align: center;
  position: relative;
  margin-bottom: 1.875rem;
  padding-bottom: .9375rem;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 2.5rem;
    background: #2d353c;
    height: .125rem;
    bottom: 0;
    left: 50%;
    margin-left: -1.25rem;
  }
}

.content-desc {
  text-align: center;
  margin-bottom: 3.75rem;
  color: #495057;
}
#header-navbar{
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 1.03rem;
}
.header {
  &.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    top: 0;
  }

  .navbar-nav .nav-item {
    .router-link-exact-active, .nav-link:hover {
      color: #05513c;
    }
  }
  .header .navbar-nav>li {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
  }
}

@media (max-width: 767.98px) {
  .header .navbar-nav {
    display: block !important;
  }
}

</style>
<script>
import PageOptions from '../../config/PageOptions.vue'
import {mapGetters} from "vuex";
import helper from "@/mixins/helper";

export default {
  name: 'Header',
  mixins: [helper],
  data() {
    return {
      showTopMenu: false,
      pageOptions: PageOptions,
      app: process.env.VUE_APP_NAME
    }
  },
  computed: {
    ...mapGetters({
      user: 'getAuthUser'
    }),
    firstname() {
      let name = this.user.firstname;
      return name != null ? name : 'user';
    },
    is_logged_in() {
      return !!this.user.email;
    }
  },
  methods: {
    toggleMobileSidebar() {
      this.pageOptions.pageMobileSidebarToggled = !this.pageOptions.pageMobileSidebarToggled;
    },
    toggleMobileRightSidebar() {
      this.pageOptions.pageMobileRightSidebarToggled = !this.pageOptions.pageMobileRightSidebarToggled;
    },
    toggleMobileTopMenu() {
      this.pageOptions.pageMobileTopMenu = !this.pageOptions.pageMobileTopMenu;
    },
    toggleMobileMegaMenu() {
      this.pageOptions.pageMobileMegaMenu = !this.pageOptions.pageMobileMegaMenu;
    },
    toggleRightSidebar() {
      this.pageOptions.pageRightSidebarToggled = !this.pageOptions.pageRightSidebarToggled;
    },
    toggleRightSidebarCollapsed() {
      this.pageOptions.pageRightSidebarCollapsed = !this.pageOptions.pageRightSidebarCollapsed;
    },
    scrollTo(id) {
      if (this.$route.name !== 'Home') {
        this.$router.replace({'name': 'Home'}).then(() => {
          this.postScroll(id);
        });
        return;
      }
      this.postScroll(id)
    },
    logout() {
      this.$axios.post('/api/v1/logout').then().finally(() => {
        this.$auth.logout();
        window.location.href = this.$router.resolve({name: 'Home'}).href;
      });
    }
  }
}
</script>

