<template>
  <div id="app">
    <dashboard-app v-if="can_view_side_bar"></dashboard-app>
    <guest-app v-else></guest-app>
  </div>
</template>

<script>
import GuestApp from '@/views/guest/GuestApp.vue'
import DashboardApp from "@/views/dashboard/DashboardApp.vue";
import {mapGetters} from 'vuex';
export default {
  name: 'app',
  components: {
    GuestApp,
    DashboardApp
  },
  computed: {
    ...mapGetters({
      user: 'getAuthUser'
    }),
    first_name() {
      let name = this.user.firstname;
      return name != null ? name : '';
    },
    can_view_side_bar() {
      return !!this.user.verified_phone && !this.user.requires_password_change && this.$router.currentRoute.name !== 'Home'
    }
  },
  created() {
    this.initiate();
  },
  methods: {
    initiate() {
      if (this.first_name) return;
      const ignore_paths = ['login', 'reset/password','register','verify/email/','company/verify/','verify-director-sms/'];
      let strLength = ignore_paths.length;
      while (strLength--) {
        if (location.pathname.indexOf(ignore_paths[strLength]) !== -1) {
          return;
        }
      }
      this.$store.dispatch('initialize');
    }
  }
}
</script>

