export default {
  install(Vue) {
    Vue.form = {
      load(id) {
        let form = document.getElementById(id);

        Object.values(form.getElementsByTagName('button')).forEach(el => {
          el.classList.add('disabled')
        })
      },

      stop(id) {
        let form = document.getElementById(id);

        Object.values(form.getElementsByTagName('button')).forEach(el => {
          el.classList.remove('disabled')
        })
      },

      validate(id, response) {
        let form = document.getElementById(id);
        this.stop(id)

        Object.values(form.getElementsByClassName('alert')).forEach((e) => {
          e.remove()
        })
  
        if (response.status == 422) {
          Object.values(form.getElementsByClassName('input-error')).forEach((e) => {
            e.remove()
          })
          
          let inputs = Object.values(form.getElementsByTagName("input"));

          let errors = response.data.errors

          if(errors) {
            inputs.forEach((input) => {
              if(Object.keys(errors).indexOf(input.name) >= 0) {
                let parent = input.parentElement
                let msg = document.createElement('span')
                msg.classList.add('input-error')
                msg.innerHTML = errors[input.name][0]
    
                parent.append(msg)
              }
            });
          } else {
            let error = document.createElement('div')
            error.classList.add('alert')
            error.classList.add('alert-warning')

            if(typeof response.data.error == 'object') {
              error.innerHTML = Object.values(response.data.error)[0]
            } else {
              error.innerHTML = response.data.error
            }
            
            form.prepend(error)
          }
        } else {
          let error = document.createElement('div')
          error.classList.add('alert')
          error.classList.add('alert-warning')

          
          if(response.status == 404) {
            error.innerHTML = 'Not Found'
          } else {
            error.innerHTML = response.data.message ? response.data.message : response.data.error
          }
          
          form.prepend(error)
        }
      }
    }

    Object.defineProperties(Vue.prototype, {
      $form: {
        get() {
          return Vue.form;
        },
      },
    });
  },
};
