import _axios from "@/plugins/axios";
export const TabTwo = {
    state: {
        commission: []
    },
    getters: {
        getCommissionTabTwo(state) {
            return state.commission;
        },
    },
    mutations: {
        setCommissionTabTwo(state, data) {
            state.commission = data;
        }
    },
    actions: {
        fetchCommissionTabTwo({commit}, payload) {
            _axios.get('/api/v1/reports/dashboard/tab-two', {
                params: {
                    start: payload.start,
                    end: payload.end
                }
            }).then(response => {
                commit('setCommissionTabTwo', response.data);
            });
        }
    },
}
