<template>
  <div class="row">
    <div class="col-sm-12 col-md-5">
      <div class="dataTables_info">
        Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }}
        entries
      </div>
    </div>
    <div class="col-sm-12 col-md-7">
      <div class="dataTables_paginate paging_simple_numbers">
        <ul class="pagination">
          <li class="page-item">
            <a
              class="page-link"
              href="#"
              :disabled="pagination.current_page === 1"
              @click.prevent="changePage(1)"
            ><i class="fa fa-backward" /></a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              href="#"
              :disabled="pagination.current_page <= 1"
              @click.prevent="changePage(pagination.current_page - 1)"
            >Previous</a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              href="#"
            >{{ pagination.current_page }} of {{ pagination.last_page }}</a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              href="#"
              :disabled="pagination.current_page >= pagination.last_page"
              @click.prevent="changePage(pagination.current_page + 1)"
            >Next</a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              :disabled="pagination.current_page >= pagination.last_page"
              @click.prevent="changePage(pagination.last_page)"
            ><i class="fa fa-forward" /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style>
ul.pagination {
  justify-content: flex-end;
}
</style>
<script>
export default {
  props: ['pagination', 'offset'],

  computed: {
    pages() {
      let pages = [];

      let from = this.pagination.current_page - Math.floor(this.offset / 2);

      if (from < 1) {
        from = 1;
      }

      let to = from + this.offset - 1;

      if (to > this.pagination.last_page) {
        to = this.pagination.last_page;
      }

      while (from <= to) {
        pages.push(from);
        from++;
      }

      return pages;
    }
  },

  methods: {
    changePage(page) {
      if (page > this.pagination.last_page) {
        page = this.pagination.last_page;
      }
      this.pagination.current_page = page;
      this.$emit('paginate');
    }
  }
}
</script>
