import _axios from "@/plugins/axios";
export const TabOne = {
    state: {
        bidbonds: []
    },
    getters: {
        getTabOneBids(state) {
            return state.bidbonds;
        },
    },
    mutations: {
        setBids(state, data) {
            state.bidbonds = data;
        }
    },
    actions: {
        fetchBidsTabOne({commit}, payload) {
            _axios.get('/api/v1/reports/dashboard/tab-one', {
                params: {
                    start: payload.start,
                    end: payload.end
                }
            }).then(response => {
                commit('setBids', response.data);
            });
        }
    },
}
