import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */'../views/Home.vue')
    },
    {
        path: '/verify/email/:code',
        name: 'verify-email',
        component: () => import(/* webpackChunkName: "VerifyEmail" */'../views/auth/VerifyEmail.vue')
    },
    {
        path: '/company/verify/:code',
        name: 'verify-company',
        component: () => import(/* webpackChunkName: "verifyCompany" */'../views/auth/verifyCompany.vue'),
    },
    {
        path: '/reset/password/:code',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "ResetPassword" */'../views/auth/ResetPassword.vue')

    },
    {
        path: '/verify/personal',
        name: 'verify.personal',
        component: () => import(/* webpackChunkName: "VerifyPersonal" */'../views/auth/VerifyPersonal.vue'),
    },
    {
        path: '/verify-director-sms/:code/:id',
        name: 'verify.director.sms',
        component: () => import(/* webpackChunkName: "VerifyDirectorSms" */'../views/auth/VerifyDirectorSms'),
    },
    {
        path: '/verify/phone',
        name: 'verify.phone',
        component: () => import(/* webpackChunkName: "VerifyPhone" */'../views/auth/VerifyPhone.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */'../views/auth/Login.vue')
    },
    {
        path: '/otp/auth',
        name: 'otp',
        component: () => import(/* webpackChunkName: "Otp" */'../views/auth/Otp.vue')
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "ForgotPassword" */'../views/auth/ForgotPassword.vue')
    },
    {
        path: '/set-password',
        name: 'set-password',
        component: () => import(/* webpackChunkName: "SetPassword" */'../views/auth/SetPassword.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "Register" */'../views/auth/Register.vue')
    },
    {
        path: '/register/confirm',
        name: 'register.confirm',
        component: () => import(/* webpackChunkName: "EmailSent" */'../views/auth/EmailSent.vue')
    },
    {
        path: '/dashboard',
        // component: () => import(/* webpackChunkName: "dashboard" */'../views/dashboard.vue'),
        // children: [{
        //     path: '',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "Homedashboard" */'../views/dashboard/Home.vue'),
        // }]
    },
    {
        path: '/companies',
        name: 'companies.index',
        component: () => import(/* webpackChunkName: "companies Index" */'../views/dashboard/companies/Index.vue')
    },
    {
        path: '/companies/create',
        name: 'companies.create',
        component: () => import(/* webpackChunkName: "companies Index" */'../views/dashboard/companies/Create.vue')
    },
    {
        path: '/companies/deleted',
        name: 'companies.deleted',
        component: () => import(/* webpackChunkName: "companies Deleted" */'../views/dashboard/companies/Deleted.vue')
    },
    {
        path: '/companies/banned',
        name: 'companies.banned',
        component: () => import(/* webpackChunkName: "companies Banned" */'../views/dashboard/companies/Banned.vue')
    },
    {
        path: '/companies/agents',
        name: 'companies.agents',
        component: () => import(/* webpackChunkName: "companies Agents" */'../views/dashboard/companies/Agents.vue')
    },
    {
        path: '/companies/amendments',
        name: 'companies.amendments',
        component: () => import(/* webpackChunkName: "Companies Amendments" */'../views/dashboard/companies/Amendments.vue')
    },
    {
        path: '/companies/cached/:crp',
        name: 'companies.cached',
        component: () => import(/* webpackChunkName: "companies Cached" */'../views/dashboard/companies/Cached.vue')
    },
    {
        path: '/companies/:id',
        name: 'companies.show',
        component: () => import(/* webpackChunkName: "companies Show" */'../views/dashboard/companies/Show.vue')
    },
    {
        path: '/companies/:id/users',
        name: 'companies.users',
        component: () => import(/* webpackChunkName: "Companies Users" */'../views/dashboard/companies/Users.vue')
    },
    {
        path: '/companies/:id/payment',
        name: 'companies.payment',
        component: () => import(/* webpackChunkName: "Companies Payment" */'../views/dashboard/companies/Payment.vue')
    },
    {
        path: '/companies/:id/searching',
        name: 'companies.searching',
        component: () => import(/* webpackChunkName: "Companies Searching" */'../views/dashboard/companies/Searching.vue')
    },
    {
        path: '/companies/:id/directors/create',
        name: 'companies.directors.create',
        component: () => import(/* webpackChunkName: "Directors Create" */'../views/dashboard/companies/directors/Create.vue')
    },
    {
        path: '/counterparties',
        name: 'counterparties.index',
        component: () => import(/* webpackChunkName: "CounterParties index" */'../views/dashboard/counterparties/Index.vue')
    },
    {
        path: '/counterparties/create',
        name: 'counterparties.create',
        component: () => import(/* webpackChunkName: "CounterParties Create" */'../views/dashboard/counterparties/Create.vue')
    },
    {
        path: '/quotes',
        name: 'quotes',
        component: () => import(/* webpackChunkName: "Quotes Index" */'../views/dashboard/quotes/Index-two.vue')
    },
    {
        path: '/quotes/create',
        name: 'quotes.create',
        component: () => import(/* webpackChunkName: "Quotes Create" */'../views/dashboard/quotes/Create.vue')
    },
    {
        path: '/bidbonds',
        name: 'bidbonds.index',
        component: () => import(/* webpackChunkName: "Bidbonds Index" */'../views/dashboard/bidbonds/Index.vue')
    },
    {
        path: '/bidbonds/create/:tender_no?/:company_id?',
        name: 'bidbonds.create',
        component: () => import(/* webpackChunkName: "Bidbonds Create" */'../views/dashboard/bidbonds/Create.vue')
    },
    {
        path: '/bidbonds/pricing',
        name: 'bidbonds.pricing',
        component: () => import(/* webpackChunkName: "Bidbond Pricing Index" */'../views/dashboard/bidbonds/pricing/Index.vue'),
    },
    {
        path: '/bidbonds/pricing/create',
        name: 'bidbonds.pricing.create',
        component: () => import(/* webpackChunkName: "Bidbond Pricing Create" */'../views/dashboard/bidbonds/pricing/Create.vue')
    },
    {
        path: '/bidbonds/pricing/group',
        name: 'bidbonds.pricing.group',
        component: () => import(/* webpackChunkName: "Pricing Groups" */'../views/dashboard/bidbonds/pricing/Groups.vue')
    },
    {
        path: '/bidbonds/pricing/settings',
        name: 'settings.bidbonds',
        component: () => import(/* webpackChunkName: "Bidbond Settings Pricing" */'../views/dashboard/bidbonds/pricing/Settings.vue')
    },
    {
        path: '/bidbonds/limits',
        name: 'bidbonds.limits',
        component: () => import(/* webpackChunkName: "Bidbond Limits" */'../views/dashboard/bidbonds/Limits.vue')
    },
    {
        path: '/bidbonds/individual/limits',
        name: 'individual.limit',
        component: () => import(/* webpackChunkName: "Individual Limit" */'../views/dashboard/bidbonds/individualLimit.vue')
    },
    {
        path: '/bidbonds/amendments',
        name: 'bidbonds.amendments',
        component: () => import(/* webpackChunkName: "Bidbonds Amendments" */'../views/dashboard/bidbonds/Amendments.vue')
    },
    {
        path: '/bidbonds/:id',
        name: 'bidbonds.show',
        component: () => import(/* webpackChunkName: "Bidbonds Show" */'../views/dashboard/bidbonds/Show.vue')
    },
    {
        path: '/bidbonds/:id/edit',
        name: 'bidbonds.edit',
        component: () => import(/* webpackChunkName: "bidbonds Edit" */'../views/dashboard/bidbonds/Edit.vue')
    },
    {
        path: '/bidbonds/:id/:co/payment',
        name: 'bidbonds.payment',
        component: () => import(/* webpackChunkName: "Bidbonds Payment" */'../views/dashboard/bidbonds/Payment.vue')
    },
    {
        path: '/bidbondtemplates',
        name: 'bidbondtemplates',
        component: () => import(/* webpackChunkName: "bidbondtemplates index" */'../views/dashboard/bidbondtemplates/Index.vue')
    },
    {
        path: '/bidbondtemplates/create',
        name: 'bidbondtemplates.create',
        component: () => import(/* webpackChunkName: "bidbondtemplates Create" */'../views/dashboard/bidbondtemplates/Create.vue')
    },
    {
        path: '/bidbondtemplates/:id',
        name: 'bidbondtemplates.show',
        component: () => import(/* webpackChunkName: "bidbondtemplates show" */'../views/dashboard/bidbondtemplates/Show.vue')
    },
    {
        path: '/bidbondtemplates/:id/edit',
        name: 'bidbondtemplates.edit',
        component: () => import(/* webpackChunkName: "bidbondtemplates edit" */'../views/dashboard/bidbondtemplates/Edit.vue')
    },
    {
        path: '/payments',
        name: 'payments',
        component: () => import(/* webpackChunkName: "Payments" */'../views/dashboard/payments/Index-two.vue')
    },
    {
        path: '/payments/create',
        name: 'payments.create',
        component: () => import(/* webpackChunkName: "Payments Create" */'../views/dashboard/payments/Create.vue')
    },
    {
        path: '/payments/:account/verify',
        name: 'payments.verify',
        component: () => import(/* webpackChunkName: "Payments" */'../views/dashboard/payments/Verify.vue')
    },
    {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "Users Index" */'../views/dashboard/users/Index.vue')
    },
    {
        path: '/users/deleted',
        name: 'users.deleted',
        component: () => import(/* webpackChunkName: "Users Deleted" */'../views/dashboard/users/Deleted.vue')
    },
    {
        path: '/users/banned',
        name: 'users.banned',
        component: () => import(/* webpackChunkName: "Users Banned" */'../views/dashboard/users/Banned.vue')
    },
    {
        path: '/users/create',
        name: 'users.create',
        component: () => import(/* webpackChunkName: "Users create" */'../views/dashboard/users/Create.vue')
    },
    {
        path: '/users/phone',
        name: 'users.phone',
        component: () => import(/* webpackChunkName: "Link Phone" */'../views/dashboard/users/EditPhone.vue')
    },
    {
        path: '/users/kyc',
        name: 'settings.kyc',
        component: () => import(/* webpackChunkName: "Settings Kyc" */'../views/dashboard/users/Kyc.vue')
    },
    {
        path: '/users/:id',
        name: 'users.show',
        component: () => import(/* webpackChunkName: "Users show" */'../views/dashboard/users/Show.vue')
    },
    {
        path: '/users/:id/edit',
        name: 'users.edit',
        component: () => import(/* webpackChunkName: "Users edit" */'../views/dashboard/users/Edit.vue')
    },
    {
        path: '/roles',
        name: 'users.roles',
        component: () => import(/* webpackChunkName: "Users Roles" */'../views/dashboard/users/Roles.vue')
    },
    {
        path: '/permissions',
        name: 'users.permissions',
        component: () => import(/* webpackChunkName: "Users permissions" */'../views/dashboard/users/Permissions.vue')
    },
    {
        path: '/customers',
        name: 'users.customers',
        component: () => import(/* webpackChunkName: "Customers" */'../views/dashboard/users/Customers.vue')
    },
    {
        path: '/relationship_managers',
        name: 'rms',
        component: () => import(/* webpackChunkName: "RMs" */'../views/dashboard/rms/Index.vue')
    },
    {
        path: '/relationship_managers/create/:id?/:rm_id?/:br_id?',
        name: 'rms.create',
        component: () => import(/* webpackChunkName: "RMs Create" */'../views/dashboard/rms/Create.vue')
    },
    {
        path: '/agents',
        name: 'agents',
        component: () => import(/* webpackChunkName: "agents index" */'../views/dashboard/agents/Index.vue')
    },
    {
        path: '/agents/deleted',
        name: 'agents.deleted',
        component: () => import(/* webpackChunkName: "agents Deleted" */'../views/dashboard/agents/Deleted.vue')
    },
    {
        path: '/agents/create',
        name: 'agents.create',
        component: () => import(/* webpackChunkName: "Create Agents" */'../views/dashboard/agents/Create.vue')
    },
    {
        path: '/agents/:id',
        name: 'agents.show',
        component: () => import(/* webpackChunkName: "agents Show" */'../views/dashboard/agents/Show.vue')
    },
    {
        path: '/banlist',
        name: 'banlist',
        component: () => import(/* webpackChunkName: "Banlists" */'../views/dashboard/banlists/Index.vue'),
    },
    {
        path: '/banlist/create',
        name: 'banlist.create',
        component: () => import(/* webpackChunkName: "Create Banlist" */'../views/dashboard/banlists/Create.vue'),
    },
    {
        path: '/marketing',
        name: 'marketing',
        component: () => import(/* webpackChunkName: "Marketing" */'../views/dashboard/Marketing.vue')
    },
    {
        path: '/marketing/groups',
        name: 'marketing.groups',
        component: () => import(/* webpackChunkName: "Marketing Groups" */'../views/dashboard/marketing/Groups.vue')
    },
    {
        path: '/marketing/schedules',
        name: 'marketing.schedules',
        component: () => import(/* webpackChunkName: "Marketing Schedules" */'../views/dashboard/marketing/Schedules.vue')
    },
    {
        path: '/marketing/sms-templates',
        name: 'marketing.templates',
        component: () => import(/* webpackChunkName: "Marketing Templates" */'../views/dashboard/marketing/SMSTemplates.vue')
    },
    {
        path: '/reports/rm-summary',
        name: 'reports.bidbonds.rm-summary',
        component: () => import(/* webpackChunkName: "RM Summary Report" */'../views/reports/bidbonds/RMSummary.vue')
    },
    {
        path: '/reports/bids-by-creator',
        name: 'reports.bidbonds.by-creator',
        component: () => import(/* webpackChunkName: "Bids By Creator Report" */'../views/reports/bidbonds/creator/Index.vue')
    },
    {
        path: '/reports/bidbonds-summary',
        name: 'reports.bidbonds.summary',
        component: () => import(/* webpackChunkName: "Bids Summary Report" */'../views/reports/bidbonds/Summary.vue')
    },
    {
        path: '/reports/bidbonds/expiry',
        name: 'reports.bidbonds.expiry',
        component: () => import(/* webpackChunkName: "Bidbonds Expiry Report" */'../views/reports/bidbonds/expiry/Index.vue')
    },
    {
        path: '/reports/bidbonds/payments',
        name: 'reports.bidbonds.payments',
        component: () => import(/* webpackChunkName: "Payments Report" */'../views/reports/payments/Index.vue')
    },
    {
        path: '/reports/bidbonds/rms',
        name: 'reports.bidbonds.rms',
        component: () => import(/* webpackChunkName: "reports rm" */'../views/reports/bidbonds/rm/breakdown.vue')
    },
    {
        path: '/reports/bidbonds/beedee-agents',
        name: 'reports.bidbonds.beedee-agents',
        component: () => import(/* webpackChunkName: "reports beedee agent" */'../views/reports/bidbonds/BeedeeAgentBids.vue')
    },
    {
        path: '/reports/commission/beedee-agents',
        name: 'reports.commission.beedee-agents',
        component: () => import(/* webpackChunkName: "reports commission beedee agent" */'../views/reports/bidbonds/BeedeeAgentCommission.vue')
    },
    {
        path: '/reports/companies/commission',
        name: 'reports.companies.commission',
        component: () => import(/* webpackChunkName: "companies commission Report" */'../views/reports/companies/Commission.vue')
    },
    {
        path: '/reports/registration/fails',
        name: 'reports.registration.fails',
        component: () => import(/* webpackChunkName: "companies commission Report" */'../views/reports/registration/fails.vue')
    },
    // {
    //     path: 'settings',
    //     name: 'settings',
    //     component: () => import(/* webpackChunkName: "Settings" */'../views/dashboard/Schedules.vue'),
    //     children: [
    // {
    //     path: 'branches',
    //     name: 'branches.list',
    //     component: () => import(/* webpackChunkName: "Branches" */'../views/dashboard/Branches/Index.vue')
    // },
    // {
    //     path: 'branches/create',
    //     name: 'branches.create',
    //     component: () => import(/* webpackChunkName: "Branches Create" */'../views/dashboard/RMs/Create.vue')
    // },


    // ]
    // },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router