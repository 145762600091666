import _axios from '../plugins/axios';

export const AuthUser = {
    state: {
        auth_user: {},
        pages: [
            15, 50, 100, 200, 500, 1000
        ]
    },

    getters: {
        getAuthUser(state) {
            return state.auth_user;
        },
        getPages(state){
            return state.pages;
        }
    },

    mutations: {
        setAuthUser(state, data) {
            state.auth_user = data;
        }
    },

    actions: {
        setAuthUser({commit}, data) {
            commit('setAuthUser', data);
        },
        
        initialize({commit}) {
            _axios.post('/api/v1/initialize').then(response => {
                commit('setAuthUser', response.data);
            });
        }
    }
};