import {amounts_colors} from "@/mixins/report_helpers";
import moment from "moment/moment";
import _axios from "@/plugins/axios";

export const BidExpiry = {
    state: {
        bid_bonds: [],
        static_bid_bonds: [],
        chart_data:[]
    },
    getters: {
        getBidExpirybonds(state) {
            return state.bid_bonds;
        },
        getBidChartData(state){
            return state.chart_data;
        },
        getBidbondsFormatted(state) {
            return state.bid_bonds.map(bid => {
                return {
                    'EXPIRY DATE': bid.date,
                    'AMOUNT': bid.amount,
                }
            });
        },
    },
    mutations: {
        setExBidbonds(state, data) {
            state.bid_bonds = data;
        },
        setExBidbondsReport(state, payload) {
            state.bid_bonds = state.static_bid_bonds.filter(b => moment(b.date).isBetween(moment(payload.from).subtract(1, 'days'), moment(payload.to)));
            state.chart_data = amounts_colors(moment(payload.from).format('M'),moment(payload.to).format('M'),state.bid_bonds);
        },
        staticExBidbondsReport(state, data) {
            state.static_bid_bonds = data;
        },
    },
    actions: {
        fetchExBidbonds({commit},payload) {
            _axios.get("/api/v1/reports/bidbonds/expiry")
                .then(res => {
                    commit("setExBidbonds", res.data);
                    commit("staticExBidbondsReport", res.data);
                    commit("setExBidbondsReport", payload);
                })
        },
        setExBidbondsReport({commit}, payload) {
            commit('setExBidbondsReport', payload)
        },
    }
}
