import _axios from "@/plugins/axios";

export const TabFive = {
    state: {
        rm_commission: []
    },
    getters: {
        getCommissionTabFive(state) {
            return state.rm_commission;
        }
    },
    mutations: {
        setCommissionTabFive(state, data) {
            state.rm_commission = data;
        }
    },
    actions: {
        fetchCommissionTabFive({commit}, payload) {
            _axios.get('/api/v1/reports/dashboard/tab-five', {
                params: {
                    start: payload.start,
                    end: payload.end
                }
            }).then(response => {
                commit('setCommissionTabFive', response.data);
            });
        }
    },
}
