import _axios from "@/plugins/axios";
export const TabEight = {
    state: {
        revenue: []
    },
    getters: {
        getBidComparisonTabEight(state) {
            return state.revenue;
        },
    },
    mutations: {
        setBidComparisonTabEight(state, data) {
            state.revenue = data;
        }
    },
    actions: {
        fetchBidComparisonTabEight({commit}, payload) {
            _axios.get('/api/v1/reports/dashboard/tab-eight', {
                params: {
                    start_date: payload.start,
                    end_date: payload.end
                }
            }).then(response => {
                commit('setBidComparisonTabEight', response.data);
            });
        }
    },
}
