import Vue from 'vue'
import Vuex from 'vuex'
import {AuthUser} from "./auth_user"
import {CounterParties} from "./counter_parties"
import {PerformanceParties} from "./perfomance_parties"
import {PostalCodes} from "./postal_codes"
import {RelationshipManagers} from "./relationship_managers";
import {BeedeeAgents} from "./beedee_agents";
import {Groups} from "./groups"
import {Report} from "./report";
import {Performance} from "./performance";
import {Branches} from "@/store/branches";
import {BidExpiry} from "@/store/bidexpiry";
import {CompaniesCommissions} from "@/store/company/commission";
import {BidPayments} from "@/store/bidbonds/payments";
import {BidbondsByCreator} from "@/store/bidbonds/creator";
import {TabOne} from "@/store/dashboard/tab_one";
import {TabTwo} from "@/store/dashboard/tab_two";
import {TabThree} from "@/store/dashboard/tab_three";
import {TabFour} from "@/store/dashboard/tab_four";
import {TabFive} from "@/store/dashboard/tab_five";
import {TabSix} from "@/store/dashboard/tab_six";
import {TabSeven} from "@/store/dashboard/tab_seven";
import {TabEight} from "@/store/dashboard/tab_eight";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        AuthUser,
        BidExpiry,
        CounterParties,
        PostalCodes,
        Groups,
        RelationshipManagers,
        BeedeeAgents,
        Report,
        Performance,
        Branches,
        PerformanceParties,
        CompaniesCommissions,
        BidPayments,
        BidbondsByCreator,
        TabOne,
        TabTwo,
        TabThree,
        TabFour,
        TabFive,
        TabSix,
        TabSeven,
        TabEight
    },

    state: {},

    mutations: {},

    actions: {}
})