"use strict";

import Vue from 'vue';
import axios from "axios";

let config = {
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'content-type': 'application/json'
    }
};
let token = localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX + 'token');
if (token != null) {
    config.headers.Authorization = 'Bearer ' + token;
}
const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);


Vue.use({
    install(Vue) {
        Vue.prototype.$axios = _axios;
    }
})

export default _axios;
