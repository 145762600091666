import Vue from "vue";
import moment from 'moment/src/moment';
Vue.filter('unslug', function (value) {
    if (!value) return '';
    value = value.replace(/_/g, ' ').toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    });
    return value;
});

Vue.filter("dateFilter", function(val) {
    return moment(val, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
});

Vue.filter("stampTodateFilter", function(val) {
    return moment(val).format("DD-MM-YYYY");
});

Vue.filter('truncate', function (text, length, suffix) {
    if (text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
});

Vue.filter('date', function (value) {
    if (value) {
        let date_value = moment(value, "D MMMM YYYY");
        return moment(date_value).format("D MMMM YYYY")
    }
});
