<template>
  <!-- begin sidebar nav -->
  <ul v-if="menus" class="nav">
    <li v-if="pageOptions.pageSidebarSearch" class="nav-search">
      <input class="form-control" placeholder="Sidebar menu filter..." type="text" v-on:keyup="handleSidebarFilter" />
    </li>
    <li class="nav-header">Navigation</li>
    <template v-for="menu in menus">
      <sidebar-nav-list
          ref="sidebarNavList"
          v-if="can(menu.view)"
          v-bind:key="menu.path"
          v-bind:menu="menu"
          v-bind:scrollTop="scrollTop"
          v-bind:status="menu.status"
          v-on:collapse-other="handleCollapseOther(menu)"
          v-on:show-float-submenu="handleShowFloatSubmenu"
          v-on:hide-float-submenu="handleHideFloatSubmenu"></sidebar-nav-list>
    </template>
    <!-- begin sidebar minify button -->
    <li><a class="sidebar-minify-btn" href="#" v-on:click="handleSidebarMinify()"><i
        class="fa fa-angle-double-left"></i></a></li>
    <!-- end sidebar minify button -->
  </ul>
  <!-- end sidebar nav -->
</template>

<script>
import SidebarMenu from './SidebarMenu.vue'
import SidebarNavList from './SidebarNavList.vue'
import PageOptions from '../../config/PageOptions.vue'

export default {
  name: 'SidebarNav',
  props: ['scrollTop'],
  components: {
    SidebarNavList
  },
  data() {
    return {
      menus: SidebarMenu,
      pageOptions: PageOptions
    }
  },
  methods: {
    can(val) {
      if (val === 'true') return true;
      if (val.indexOf(',') === -1) return this.$can.view(this.$store.getters.getAuthUser, val);
      let permissions = val.split(',');
      let status = false;
      for (let p of permissions)
      {
        status = this.$can.view(this.$store.getters.getAuthUser, p);
        if (status) break;
      }
      return status;
    },
    handleShowFloatSubmenu: function (menu, offset) {
      this.$emit('show-float-submenu', menu, offset);
    },
    handleHideFloatSubmenu: function () {
      this.$emit('hide-float-submenu');
    },
    handleCollapseOther: function (menu) {
      for (let i = 0; i < this.menus.length; i++) {
        this.$refs.sidebarNavList[i].collapse(menu);
      }
    },
    handleSidebarMinify: function () {
      this.pageOptions.pageSidebarMinified = !this.pageOptions.pageSidebarMinified;
    },
    handleSidebarFilter: function (e) {
      let value = e.target.value;
      value = value.toLowerCase();

      if (value) {
        for (let x = 0; x < this.menus.length; x++) {
          let title = (this.menus[x].title).toLowerCase();
          let children = this.menus[x].children;

          if (title.search(value) > -1) {
            this.$refs.sidebarNavList[x].show();

            if (children) {
              this.$refs.sidebarNavList[x].searchExpand();
            }
          } else {
            if (children) {
              let hasActive = false;
              for (let y = 0; y < children.length; y++) {
                let title2 = (children[y].title).toLowerCase();

                if (title2.search(value) > -1) {
                  hasActive = true;
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].show();
                  this.$refs.sidebarNavList[x].searchExpand();
                } else {
                  if (hasActive) {
                    this.$refs.sidebarNavList[x].searchExpand();
                  } else {
                    this.$refs.sidebarNavList[x].hide();
                  }
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].hide();
                }
              }
            } else {
              this.$refs.sidebarNavList[x].hide();
            }
          }
        }
      } else {
        for (let a = 0; a < this.menus.length; a++) {
          this.$refs.sidebarNavList[a].show();

          let submenu = this.menus[a].children;
          if (submenu) {
            for (let b = 0; b < submenu.length; b++) {
              this.$refs.sidebarNavList[a].$refs.sidebarNavList[b].show();
            }
          }
        }
      }
    }
  }
}
</script>
