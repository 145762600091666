<script>
const sidebarMenu = [
  {
    path: '/dashboard', icon: 'fas fa-th', view: 'list-companies,list-companies-owned', title: 'Dashboard',
  },
  {
    path: '/companies', icon: 'fas fa-briefcase', view: 'list-companies,list-companies-owned', title: 'Companies',
    children: [
      {path: '/companies/create', view: 'create-companies', title: 'Create Company'},
      {path: '/companies', view: 'list-companies,list-companies-owned', title: 'List Companies'},
      {path: '/companies/banned', view: 'view-banlists', title: 'Banned'},
      {path: '/companies/agents', view: 'list-agent-companies', title: 'Agents'},
      {path: '/companies/amendments', view: 'view-company-amendments', title: 'Amendments'},
      {path: '/companies/deleted', view: 'restore-companies', title: 'Deleted'}
    ]
  },
  {
    path: '/quotes', icon: 'fas fa-clipboard', view: 'list-quotes,list-quotes-owned', title: 'Quotes',
    children: [
      {path: '/quotes', view: 'list-quotes,list-quotes-owned', title: 'Quotes'},
      {path: '/quotes/create', view: 'true', title: 'Request Quote'},
    ]
  },
  {
    path: '/bidbonds',
    icon: 'fa fa-gem',
    view: 'list-bidbonds,list-bidbonds-owned,list-bidbond-pricing,manage-settings',
    title: 'Bidbonds',
    children: [
      {path: '/bidbonds/create', view: 'create-bidbonds', title: 'Generate bid bond'},
      {path: '/bidbonds', view: 'list-bidbonds,list-bidbonds-owned', title: 'View bid bonds'},
      {path: '/bidbonds/pricing', view: 'list-bidbond-pricing', title: 'Pricing'},
      {path: '/bidbonds/pricing/group', view: 'list-bidbond-pricing', title: 'Pricing Group'},
      {path: '/bidbonds/pricing/settings', view: 'manage-settings', title: 'Price Settings'},
      {path: '/bidbonds/limits', view: 'manage-settings', title: 'Bidbond Limits'},
      {path: '/bidbonds/amendments', view: 'view-bid-amendments', title: 'Amendments'},
      {path: '/bidbonds/individual/limits', view: 'add-individual-limit', title: 'Individual Limit'},
    ]
  },
  {
    path: '/bidbondtemplates', icon: 'fas fa-file-word', view: 'list-bidbond-templates', title: 'Bidbond Templates'
  },
  {
    path: '/counterparties', icon: 'fas fa-briefcase', view: 'list-counterparties', title: 'CounterParties'
  },
  {path: '/payments',
    icon: 'fas fa-money-bill-alt',
    view: 'list-payments,list-payments-owned',
    title: 'Payments',
    children: [
      {path: '/payments/create', icon: 'fas fa-money-bill', view: 'create-payment', title: 'Create Payment'},
      {path: '/payments', icon: 'fas fa-money-bill-alt', view: 'list-payments,list-payments-owned', title: 'All Payments'},
    ]
  },
  // {path: '/payments', icon: 'fas fa-money-bill-alt', view: 'list-payments,list-payments-owned', title: 'Payments'},
  //
  // {path: '/payments/user', icon: 'fas fa-money-bill-alt', view: 'list-payments-user', title: 'My Payments'},

  {
    path: '/users', icon: 'fas fa-users', view: 'list-users,list-customers,list-relationship-managers', title: 'Users',
    children: [
      {path: '/users', view: 'list-users', title: 'Users'},
      {path: '/users/banned', view: 'view-banlists', title: 'Banned Users'},
      {path: '/users/deleted', view: 'delete-users', title: 'Deleted Users'},
      {path: '/customers', view: 'list-customers', title: 'Customers'},
      {path: '/relationship_managers', view: 'list-relationship-managers', title: 'Relationship Managers'},
      {path: '/permissions', view: 'attach-permissions', title: 'Permissions'},
      {path: '/roles', view: 'create-roles', title: 'Roles'},
      {path: '/users/kyc', view: 'kyc-settings', title: 'Manage KYC'},
    ]
  },
  {
    path: '/agents', icon: 'fas fa-users', view: 'list-agents', title: 'Agents',
    children: [
      {path: '/agents', view: 'list-agents', title: 'Agents'},
      {path: '/agents/deleted', view: 'restore-agents', title: 'Deleted Agents'}
    ]
  },
  {
    path: '/marketing', icon: 'fas fa-chart-pie', view: 'marketing', title: 'Marketing',
    children: [
      {path: '/marketing/groups', icon: 'fas fa-users', view: 'marketing', title: 'Groups'},
      {path: '/marketing/sms-templates', icon: 'fas fa-doc', view: 'marketing', title: 'SMS Templates'},
      {path: '/marketing/schedules', icon: 'fas fa-cogs', view: 'marketing', title: 'Schedules'},
    ]
  },
  {path: '/banlist', icon: 'fas fa-ban', view: 'view-banlists', title: 'Banlist'},
  {
    path: '/reports', icon: 'fas fa-chart-pie', view: 'list-reports', title: 'Reports',
    children: [
      {path: '/reports/rm-summary', view: 'list-reports', title: 'RM Summary'},
      {path: '/reports/bids-by-creator', view: 'list-reports', title: 'Bidbonds By Creator'},
      {path: '/reports/bidbonds-summary', view: 'list-reports', title: 'Bidbonds Summary'},
      {path: '/reports/bidbonds/expiry', view: 'list-reports', title: 'Bidbonds Expiry'},
      {path: '/reports/bidbonds/rms', view: 'list-reports', title: 'RM Breakdown'},
      {path: '/reports/bidbonds/beedee-agents', view: 'list-reports', title: 'Beedee Agent Breakdown'},
      {path: '/reports/commission/beedee-agents', view: 'list-agent-reports', title: 'Beedee Agent Commission'},
      {path: '/reports/bidbonds/payments', view: 'list-reports', title: 'Bidbond Payments'},
      {path: '/reports/companies/commission', view: 'list-reports', title: 'Companies Commission'},
      {path: '/reports/registration/fails', view: 'list-reports', title: 'Failed Registration'},
    ]
  }
  // {path: '/bootstrap-4', img: '/assets/img/logo/logo-bs4.png', view:'true',title: 'Bootstrap 4', label: 'NEW'},

]

export default sidebarMenu;
</script>