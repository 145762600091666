export default {
    methods: {
        fullname(user) {
            if (user) {
                const n = ['firstname', 'middlename', 'lastname'];
                let names = Object.keys(user)
                    .map((key) => {
                        if (n.indexOf(key) !== -1)
                            return user[key];
                    });
                    names.filter(function (item,pos){
                        return names.indexOf(item) === pos;
                    });
                return names.join(' ');
            } else {
                return "None";
            }
        },
        formatFn(value) {
            return new Intl.NumberFormat().format(value)
        },
        postScroll(id) {
            const element = document.getElementById(id);
            element.scrollIntoView({behavior: "smooth", block: "end"});
        },
    }
}