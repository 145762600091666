import _axios from '../plugins/axios';

export const PerformanceParties = {
    state: {
        performance_parties: [],
        categories: [],
        secure_states: [
            {'label': 'Secured', 'value': true},
            {'label': 'Unsecured', 'value': false}
        ]
    },

    getters: {
        getPerformanceParties(state) {
            return state.performance_parties;
        },
        getCategories(state) {
            return state.categories;
        },
        getSecureStates(state) {
            return state.secure_states;
        }
    },

    mutations: {
        setPerformanceParties(state, data) {
            state.performance_parties = data;
        },
        setCategories(state, data) {
            state.categories = data;
        },
        setPerformanceParty(state, data) {
            const index = state.performance_parties.findIndex(d => d.id === data.id);
            if (index === -1)
                state.performance_parties.push(data);
            else
                state.performance_parties[index] = data;
        },
        deletePerformanceParty(state, data) {
            const i = state.performance_parties.map(item => item.id).indexOf(data.id);
            state.performance_parties.splice(i, 1);
        }
    },

    actions: {
        setPerformanceParty({commit}, data) {
            commit('setPerformanceParty', data);
        },
        deletePerformanceParty({commit}, data) {
            commit('deletePerformanceParty', data);
        },
        fetchPerformanceParties({commit}) {
            _axios.get('/api/v1/performance-counterparties').then(response => {
                commit('setPerformanceParties', response.data);
            });
        },
        fetchCategories({commit}) {
            _axios.get('/api/v1/performance-categories').then(response => {
                commit('setCategories', response.data);
            });
        }
    }
};


