// import {amounts_colors} from "@/mixins/report_helpers.js";
import moment from "moment/moment";
import _axios from "@/plugins/axios";

export const BidPayments = {
    state: {
        payments: [],
        chart_data: [],
    },
    getters: {
        getPayments(state) {
            return state.payments;
        },
        // getPaymentsChartData(state) {
        //     return state.chart_data;
        // },
        getPaymentsFormatted(state) {
            return state.payments.map(comm => {
                return {
                    'Amount': comm.amount,
                    'Transaction Number': comm.transaction_number,
                    'Reference': comm.reference,
                    'Deal Date': comm.deal_date,
                    'Transaction Date': comm.transaction_date,
                    'Account': comm.account,
                }
            });
        },
    },
    mutations: {
        setPayments(state, data) {
            state.payments = data;
        },
        // setPaymentsData(state, payload) {
        //     state.chart_data = amounts_colors(moment(payload.from).format('M'), moment(payload.to).format('M'), state.payments);
        // },
    },
    actions: {
        fetchPayments({commit}, payload) {
            _axios.get("/api/v1/reports/bidbonds/payments", {
                params:
                    {
                        start: moment(payload.from).format('YYYY-MM-DD'),
                        end: moment(payload.to).format('YYYY-MM-DD'),
                    }
            }).then(res => {
                    commit("setPayments", res.data);
                    // commit("setPaymentsData", payload);
                });
        },
        // setPayments({commit}, payload) {
        //     commit('setPaymentsData', payload);
        // },
    },

}