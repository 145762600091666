import _axios from "@/plugins/axios";

export const TabThree = {
    state: {
        tenders: [],
        opportunity: 0,
    },
    getters: {
        getTabThreeTenders(state) {
            return state.tenders;
        },
        getTabThreeOpportunity(state) {
            return state.opportunity;
        },
    },
    mutations: {
        setTenderData(state, data) {
            state.tenders = data.tenders;
            state.opportunity = data.total_opportunity
        }
    },
    actions: {
        fetchTendersTabThree({commit}) {
            _axios.get('/api/v1/reports/dashboard/tab-three').then(response => {
                commit('setTenderData', response.data);
            });
        }
    },
}
