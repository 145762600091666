import _axios from '../plugins/axios';
export const Groups = {
	state: {
		groups: [],
		performance_groups: [],
	},

	getters: {
		getGroups(state) {
			return state.groups;
		},
		getPerformanceGroups(state) {
			return state.performance_groups;
		}
	},

	mutations: {
		setGroups(state, data) {
			state.groups = data;
		},
		setPerformanceGroups(state, data) {
			state.performance_groups = data;
		},
		setPerformanceGroup(state, data) {
			const index = state.performance_groups.findIndex(d => d.id === data.id);
			if (index === -1)
				state.performance_groups.push(data);
			else
				state.performance_groups[index] = data;
		},
		setGroup(state, data) {
			const index = state.groups.findIndex(d => d.id === data.id);
			if (index === -1)
				state.groups.push(data);
			else
				state.groups[index] = data;
		},
        deleteGroup(state,data){
            const i = state.groups.map(item => item.id).indexOf(data.id);
            state.groups.splice(i, 1);
        },
		deletePerformanceGroup(state,data){
			const i = state.groups.map(item => item.id).indexOf(data.id);
			state.performance_groups.splice(i, 1);
		}
	},

	actions: {
		setGroup({ commit }, data) {
			commit('setGroup', data);
		},
		setPerformanceGroup({ commit }, data) {
			commit('setPerformanceGroup', data);
		},
        deleteGroup({ commit }, data) {
            commit('deleteGroup', data);
        },
		deletePerformanceGroup({ commit }, data) {
			commit('deletePerformanceGroup', data);
		},
		fetchGroups({commit}) {
			_axios.get('/api/v1/groups').then(response => {
				commit('setGroups', response.data);
			});
		},
		fetchPerformanceGroups({commit}) {
			_axios.get('/api/v1/performance-groups').then(response => {
				commit('setPerformanceGroups', response.data);
			});
		}
	}
};


