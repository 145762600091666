import _axios from "@/plugins/axios";
import moment from "moment/moment";

export const BidbondsByCreator = {
    state: {
        bids_by_creator: [],
        by_customer: [],
        by_rm: []
    },
    getters: {
        getBidsByCreator(state) {
            return state.bids_by_creator;
        },
        getBidsByRm(state) {
            return state.by_rm;
        },
        getBidsByCustomer(state) {
            return state.by_customer;
        },
        getBidbondsCreatorFormatted(state) {
            return state.bids_by_creator.map(bid => {
                return {
                    'Bidbond Reference': bid.reference,
                    'Company': bid.company,
                    'CounterParty': bid.counter_party,
                    'Amount': bid.amount,
                    'Currency': bid.currency,
                    'Deal Date': bid.deal_date,
                    'Created By': bid.creator,
                    'Role': bid.bidbond_creator
                }
            });
        },
    },
    mutations: {
        BidsByCreator(state, data) {
            state.bids_by_creator = data;
        },
        setByRm(state, data) {
            let month_array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            let months = [];
            data.forEach(x => {
                months.push(x.month)
            });
            month_array = month_array.map(x => {
                return months.indexOf(x) === -1 ? "0" : x
            });
            data.forEach(x => {
                let index = month_array.indexOf(x.month);
                month_array[index] = x.bid_exposure;
            });
            state.by_rm = month_array;
        },
        setByCustomer(state, data) {
            let month_array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            let months = [];
            data.forEach(x => {
                months.push(x.month)
            });
            month_array = month_array.map(x => {
                return months.indexOf(x) === -1 ? "0" : x
            });
            data.forEach(x => {
                let index = month_array.indexOf(x.month);
                month_array[index] = x.bid_exposure;
            });
            state.by_customer = month_array;
        },
    },
    actions: {
        fetchBidsByCreator({commit}, payload) {
            _axios.get('/api/v1/reports/bidbonds/bids-by-creator', {
                params: {
                    start: moment(payload.start).format("YYYY-MM-DD"),
                    end: moment(payload.end).format("YYYY-MM-DD"),
                }
            }).then(response => {
                commit('BidsByCreator', response.data.bids);
                commit("setByRm", response.data.by_rm);
                commit("setByCustomer", response.data.by_customer);
            });
        }
    },
}
