
export const colors = [
    '#fb5597', '#f59c1a', '#348fe2', '#00acac', '#8753de', '#32a852', '#a84e32', '#a89632', '#3e32a8', '#a032a8', '#3294a8', '#f2edf2'
];

export const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

export const amounts_colors = (from, to, data) => {
    let month_diff = (to - from) + 1;
    let amounts = [];
    let used_colors = [];
    let used_months = [];
    for (let i = from; i <= month_diff; i++) {
        let items = data.filter(b => parseInt(b.month) === i);
        if (items.length > 0) {
            amounts.push(items.reduce((a, b) => +a + +b.amount, 0));
            used_colors.push(colors[i - 1]);
            used_months.push(months[i - 1]);
        }
    }

    return [amounts, used_colors, used_months];
}
