<template>
  <!-- begin #footer -->
  <div id="footer" class="footer">
    &copy; 2024 {{ app }}" All Rights Reserved
  </div>
  <!-- end #footer -->
</template>

<script>
import PageOptions from '../../config/PageOptions.vue'

export default {
  name: 'Footer',
  data() {
    return {
      app: process.env.VUE_APP_NAME,
      pageOptions: PageOptions
    }
  }
}
</script>
