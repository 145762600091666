// import {commSummary} from "../../../helpers/general";
import moment from "moment/moment";
import _axios from "@/plugins/axios";

export const CompaniesCommissions = {
    state: {
        commissions: [],
        static_commissions: [],
        chart_data: [],
        companies: [],
        percent: 0,
        // commission_summary: []
    },
    getters: {
        getCommissions(state) {
            return state.commissions;
        },
        getCommissionChartData(state) {
            return state.chart_data;
        },
        getCommCompanies(state) {
            return state.companies;
        },
        getPercent(state) {
            return state.percent;
        },
        // getCommissionSummary(state) {
        //     return state.commission_summary;
        // },
        getCommissionsFormatted(state) {
            return state.commissions.map(comm => {
                return {
                    'Company': comm.name,
                    'Currency': comm.currency,
                    'Amount': comm.amount,
                    'Commission': comm.commission,
                    'Bid Bonds': comm.bidbond_count,
                }
            });
        },
    },
    mutations: {
        fetchCommissions(state, data) {
            state.commissions = data;
        },
        setCommissions(state, payload) {
            state.commissions = state.static_commissions.filter(b => moment(b.created).isBetween(moment(payload.from, "YYYY-MM-DD").subtract(1, 'days'), moment(payload.to, "YYYY-MM-DD").add(1, 'days')));
        },
        staticCommissions(state, data) {
            state.static_commissions = data;
        },
        setCommCompanies(state, data) {
            state.companies = data.map(x => {
                return {id: x.companyID, name: x.name}
            });
        },
        setCommPercent(state, data) {
            state.percent = data;
        },
        // fetchCommissionsSummary(state, data) {
        //     state.commission_summary = data;
        // }
    },
    actions: {
        fetchCommissions({commit}, payload) {
            _axios.get("/api/v1/reports/companies/commission")
                .then(res => {
                    commit("fetchCommissions", res.data.data);
                    commit("staticCommissions", res.data.data);
                    commit("setCommCompanies", res.data.data);
                    commit("setCommPercent", res.data.percentage);
                    commit("setCommissions", payload);
                });
        },
        // fetchCommissionsSummary({commit}, payload) {
        //     _axios.get("/companies/commission-summary")
        //         .then(res => {
        //             commit("fetchCommissionsSummary", res.data);
        //         });
        // },
        setCommissions({commit}, payload) {
            commit('setCommissions', payload);
        },
    },

}
