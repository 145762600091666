import _axios from '../plugins/axios';
export const Performance = {
    state: {
        labor_limit: null,
    },

    getters: {
        getLaborLimit(state) {
            return state.labor_limit;
        },
    },

    mutations: {
        setLaborLimit(state, data) {
            state.labor_limit = data;
        },
    },

    actions: {
        fetchLaborLimit({commit}) {
            _axios.get('/api/v1/performances/laborlimit').then(response => {
                commit('setLaborLimit', response.data.labor_limit);
            });
        }
    }
};


