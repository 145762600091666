<template>
  <div>
    <input
      v-model="val"
      type="tel"
      class="form-control"
      placeholder="eg. 254712345678"
      autocomplete="tel"
      pattern="[254][0-9]{11}"
      maxlength="12"
      minlength="12"
      @input="handle"
      @focusout="format"
			:name="name"
    >
    <span
      v-if="invalid"
      class="error-text"
    >The phone number is invalid.</span>
  </div>
</template>

<script>
	export default {
		data() {
			return {
				val: this.value,
				invalid: false
			}
		},

		props: {
			name: {
				default() {
					return 'phone_number'
				}
			},
			value: {
				default() {
					return null
				}
			}
		},

		mounted() {
			this.val = 254;
		},

		methods: {
			handle() {
				this.$emit('input', this.val);
			},

			format() {
				if(!this.val){
					this.invalid = false;
					return;
				}

				if (! this.val.startsWith('254')) {
					this.val = '254' + this.val;
				}

				this.invalid = this.val.length !== 12;

				this.handle();
			}
		}
	}
</script>